import tw, { styled } from 'twin.macro';

import { Props } from './TextStyle';

type StyledProps = Pick<Props, 'variation'>;

export const StyledTextStyle = styled.span<StyledProps>(({ variation }) => [
	tw`mb-0 text-current`,
	variation === 'lineThrough' && tw`line-through`,
	variation === 'strong' && tw`font-semibold`,
	variation === 'subdued' && tw`text-gray-500`,
	variation === 'negative' && tw`text-red-700`,
	variation === 'primary' && tw`text-indigo-500`,
]);
