import { FC, ReactNode } from 'react';

import { StyledHeading } from './Heading.styles';

export type Align = 'center' | 'left' | 'right';
export type Level = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export interface Props {
	/**
	 * Name of element to use
	 * @default 'h1'
	 */
	element?: Level;
	/** The title attribute */
	title?: string;
	/** The alignment of the title */
	align?: Align;
	children?: ReactNode;
	/** Truncate the title */
	truncate?: boolean;
}

export const Heading: FC<Props> = ({ children, element = 'h1', align = 'left', truncate = false, ...props }) => (
	<StyledHeading as={element} align={align} level={element} truncate={truncate} {...props}>
		{children}
	</StyledHeading>
);
