import { useCallback, useState } from 'react';

export const useToggle = (
	initialState = false
): { onClose: () => void; onOpen: () => void; onToggle: () => void; toggled: boolean } => {
	const [toggled, setToggled] = useState(initialState);

	const onClose = useCallback(() => {
		setToggled(false);
	}, []);
	const onOpen = useCallback(() => {
		setToggled(true);
	}, []);
	const onToggle = useCallback(() => {
		setToggled((toggled) => !toggled);
	}, []);

	return { onClose, onOpen, onToggle, toggled } as const;
};
