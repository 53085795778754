import tw, { styled } from 'twin.macro';

import { Props } from './Content';

export const StyledContentWrapper = styled.div<Omit<Props, 'context'>>`
	${tw`min-w-max bg-white rounded shadow-md border`}
	z-index: 555556; // higher than the overlay

	${({ fullWidth }) => fullWidth && tw`w-fit`}
`;
