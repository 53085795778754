import { createGlobalStyle, CSSObject } from 'styled-components';
import { globalStyles } from 'twin.macro';

// Remove the unwanted button reset CSS from Tailwind 3
// See: https://github.com/ben-rogerson/twin.macro/issues/773#issuecomment-1386080633
const filteredGlobalStyles = Object.fromEntries(
	Object.entries(globalStyles).filter((k, v) => k[0] !== "button, [type='button'], [type='reset'], [type='submit']")
);

const CustomGlobalStyles = createGlobalStyle(filteredGlobalStyles as CSSObject);

export default CustomGlobalStyles;
