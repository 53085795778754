import tw, { css, styled, theme } from 'twin.macro';

export const StyledCheckbox = styled.input<{ disabled: boolean }>(({ disabled }) => [
	tw`w-4 h-4 transition duration-150 ease-in-out bg-white border border-gray-300 rounded appearance-none focus:border-indigo-500 focus:ring-indigo-100 focus:ring-4 focus:outline-none`,
	disabled ? tw`cursor-default` : tw`cursor-pointer`,

	css`
		&:checked {
			${tw`bg-center bg-no-repeat border-indigo-500 bg-indigo-500`}
			background-size: 100% 100%;
			background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='${encodeURIComponent(
				theme`colors.white`
			)}' stroke='${encodeURIComponent(
				theme`colors.white`
			)}' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");

			&:disabled {
				${tw`bg-center bg-no-repeat border-gray-300 bg-gray-200`}

				background-size: 100% 100%;
				background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='${encodeURIComponent(
					theme`colors.gray.400`
				)}' stroke='${encodeURIComponent(
					theme`colors.gray.400`
				)}' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
			}
		}
	`,
]);
