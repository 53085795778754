import { FC, HTMLAttributes, MouseEvent, ReactNode } from 'react';

import { StyledTextStyle } from './TextStyle.styles';

type Variation = 'strong' | 'normal' | 'subdued' | 'negative' | 'primary' | 'lineThrough';

export interface Props extends HTMLAttributes<HTMLSpanElement> {
	onClick?: (event: MouseEvent) => void;
	variation?: Variation;
	children?: ReactNode;
}

const TextStyle: FC<Props> = ({ children, onClick, variation = 'strong', ...rest }) => (
	<StyledTextStyle onClick={onClick} variation={variation} {...rest}>
		{children}
	</StyledTextStyle>
);

TextStyle.displayName = 'TextStyle';
export { TextStyle };
