import 'twin.macro';

import { useToggle } from '@cheqroom/hooks';
import { Cross } from '@cheqroom/icons';
import { ComponentPropsWithoutRef, DetailedHTMLProps, forwardRef, InputHTMLAttributes, ReactNode, useId } from 'react';

import { Card } from '../Card/Card';
import { Icon, IconSource } from '../Icon/Icon';
import { IconButton, Props as IconButtonProps } from '../IconButton/IconButton';
import { Labelled, Props as LabelledProps } from '../Labelled/Labelled';
import { Popover } from '../Popover/Popover';
import { Stack } from '../Stack/Stack';
import { Prefix, StyledInput, StyledInputWrapper, Suffix } from './Input.styles';

export interface Props extends Omit<ComponentPropsWithoutRef<'input'>, 'prefix' | 'suffix'>, LabelledProps {
	/**
	 * Determine type of input
	 * @default 'text'
	 */
	type?: 'text' | 'number' | 'email' | 'password' | 'date';
	/** Show clear button */
	clearButton?: boolean;
	/** Inner button to show. e.g Clear button, Copy button */
	innerButton?: {
		onClick: (value: InputHTMLAttributes<unknown>['value']) => unknown;
		icon: IconSource;
		popoverContent?: string | ReactNode;
	};
	outerButton?: IconButtonProps;
	/** Callback when value is cleared */
	onClear?: () => void;
	/**
	 * Placeholder text
	 */
	placeholder?: string;
	/** Text to display before value */
	prefix?: ReactNode;
	/** Text to display after value */
	suffix?: ReactNode;
	/** Show ellipsis when the text overflows the input */
	ellipsis?: boolean;
	/** Props to pass to the container */
	containerProps?: Record<string, unknown>;
	inputProps?: DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
	propagate?: boolean;
	/** Prevents the input from changing when scrolling */
	preventChangeOnScroll?: boolean;
}

const Input = forwardRef<HTMLInputElement, Props>(
	(
		{
			clearButton = false,
			innerButton,
			outerButton,
			containerProps,
			inputProps,
			error,
			helpText,
			id,
			label,
			labelHidden,
			onChange,
			onClear,
			placeholder,
			prefix,
			readOnly,
			required,
			suffix,
			ellipsis = true,
			tabIndex,
			type = 'text',
			value,
			propagate = false,
			preventChangeOnScroll = false,
			...rest
		},
		ref
	) => {
		const generatedId = useId();
		const { toggled: showPopover, onClose: closePopover, onToggle: togglePopover } = useToggle();

		// Set the inner button to the clear button if it's defined,
		// otherwise fall back to the inner button defined in the props
		const innerButtonProps: Props['innerButton'] | undefined =
			clearButton && onClear && !!value ? { onClick: onClear, icon: Cross } : innerButton;

		const InnerButton = innerButtonProps && (
			<Popover
				placement="left"
				toggled={innerButtonProps.popoverContent ? showPopover : false}
				onClose={closePopover}
				onToggle={() => {
					innerButtonProps.onClick(value);
					togglePopover();
				}}
			>
				<Popover.Trigger>
					<button type="button">
						<Icon
							source={innerButtonProps.icon}
							size="small"
							tw="text-gray-500 cursor-pointer focus:outline-none"
						/>
					</button>
				</Popover.Trigger>
				<Popover.Content>
					<Card tw="py-1 px-2">{innerButtonProps.popoverContent}</Card>
				</Popover.Content>
			</Popover>
		);

		const OuterButton = outerButton && <IconButton {...outerButton} />;

		return (
			<Labelled
				error={error}
				helpText={helpText}
				htmlFor={id ?? generatedId}
				label={label}
				labelHidden={labelHidden}
				required={required}
				{...containerProps}
				{...rest}
			>
				<Stack tw="gap-2">
					<StyledInputWrapper error={error} readOnly={readOnly} ellipsis={ellipsis}>
						{prefix && <Prefix>{prefix}</Prefix>}

						<StyledInput
							{...rest}
							{...inputProps}
							onClick={(event) => {
								event.stopPropagation();
								rest.onClick?.(event);
							}}
							onKeyDown={(event) => {
								!propagate && event.stopPropagation();
								rest.onKeyDown?.(event);
							}}
							onKeyUp={(event) => {
								!propagate && event.stopPropagation();
								rest.onKeyUp?.(event);
							}}
							id={id ?? generatedId}
							onChange={onChange}
							placeholder={placeholder}
							readOnly={readOnly}
							ellipsis={ellipsis}
							ref={ref}
							required={required}
							tabIndex={tabIndex}
							type={type}
							value={value}
							onWheel={(event) => {
								preventChangeOnScroll && event.currentTarget.blur();
								rest.onWheel?.(event);
							}}
						/>

						{suffix && <Suffix>{suffix}</Suffix>}

						{InnerButton}
					</StyledInputWrapper>

					{OuterButton}
				</Stack>
			</Labelled>
		);
	}
);

Input.displayName = 'Input';
export { Input };
