import tw, { css, styled } from 'twin.macro';

import { Props } from './Input';

type StyledProps = Pick<Props, 'error' | 'readOnly' | 'ellipsis'>;

export const StyledInput = styled.input<StyledProps>(({ readOnly, ellipsis }) => [
	tw`flex-1 max-w-full min-w-0 text-gray-700 text-sm leading-5 placeholder-gray-400 placeholder-opacity-100 bg-white focus:outline-none`,

	ellipsis && tw`text-ellipsis overflow-hidden whitespace-nowrap`,

	readOnly && tw`cursor-default bg-gray-100`,

	css`
		// Firefox overwrites default opacity
		&::placeholder {
			${tw`opacity-100`};
		}

		// Remove webkit autofill yellow
		&:-webkit-autofill,
		&:-webkit-autofill:active,
		&:-webkit-autofill:focus,
		&:-webkit-autofill:hover {
			-webkit-transition:
				color 9999s ease-out,
				background-color 9999s ease-out;
			-webkit-transition-delay: 9999s;
		}
	`,
]);

export const StyledInputWrapper = styled.div<StyledProps>(({ error, readOnly }) => [
	// Base
	tw`flex items-center w-full text-base leading-4 bg-white rounded-md py-2 px-2 overflow-hidden`,

	// Default
	tw`border border-gray-300 border-solid`,

	// Focus
	!readOnly &&
		tw`focus-within:border-indigo-300 focus-within:shadow-none focus-within:ring-4 focus-within:ring-indigo-100`,

	// Disabled
	readOnly && tw`bg-gray-100 cursor-default`,

	error &&
		tw`border-red-300 focus-within:border-red-300 focus-within:shadow-none focus-within:ring-4 focus-within:ring-red-100`,
]);

export const Prefix = tw.div`pr-3 flex flex-grow-0 flex-shrink-0 text-gray-700`;
export const Suffix = tw.div`pl-3 flex flex-grow-0 flex-shrink-0 text-gray-700`;
