module.exports = {
	theme: {
		colors: {
			white: '#fff',
			black: '#000',
			transparent: 'transparent',
			current: 'currentColor',
			gray: {
				50: '#F8FAFC',
				100: '#F1F5F9',
				200: '#E2E8F0',
				300: '#CBD5E1',
				400: '#94A3B8',
				500: '#64748B',
				600: '#475569',
				700: '#334155',
				800: '#1E293B',
				900: '#0F172A',
			},
			indigo: {
				50: '#F3F3FF',
				100: '#EAE9FE',
				200: '#D8D6FE',
				300: '#BBB5FD',
				500: '#7B61FF',
				700: '#5428D9',
				900: '#3B1D95',
			},
			blue: {
				50: '#EFF6FF',
				100: '#DBEAFE',
				200: '#BFDBFE',
				300: '#93C5FD',
				500: '#3B82F6',
				700: '#1D4ED8',
				900: '#1E3A8A',
			},
			yellow: {
				50: '#FFFBEB',
				100: '#FFF5E0',
				200: '#FDE68A',
				300: '#FCD34D',
				500: '#F59E0B',
				700: '#B45309',
				900: '#78350F',
			},
			red: {
				50: '#FEF2F2',
				100: '#FEE2E2',
				200: '#FECACA',
				300: '#FCA5A5',
				500: '#EF4444',
				700: '#B91C1C',
				900: '#7F1D1D',
			},
			green: {
				50: '#ECFDF5',
				100: '#D1FAE5',
				200: '#A7F3D0',
				300: '#6EE7B7',
				500: '#10B981',
				600: '#059669',
				700: '#047857',
				900: '#064E3B',
			},
			chart: {
				green: '#00B9A9',
				orange: '#F25B1F',
			},
		},
		extend: {
			width: {
				91: '364px',
				200: '800px',
			},
			minWidth: {
				12: '48px',
				14: '56px',
				37: '148px',
				100: '400px',
			},
			maxWidth: {
				'2/3': '66.666667%;',
				70: '280px',
				160: '640px',
			},
			height: {
				'fit-content': 'fit-content',
				'min-content': 'min-content',
			},
			minHeight: {
				7: '28px',
				9: '36px',
				10: '40px',
				11: '44px',
				12: '48px',
				13: '52px',
				16: '64px',
			},
			boxShadow: {
				xs: '0px 1px 2px rgba(15, 23, 42, 0.05)',
				sm: '0px 1px 2px rgba(15, 23, 42, 0.06), 0px 1px 3px rgba(15, 23, 42, 0.1);',
				md: '0px 2px 4px -2px rgba(15, 23, 42, 0.06), 0px 4px 8px -2px rgba(15, 23, 42, 0.1)',
				lg: '0px 4px 6px -2px rgba(15, 23, 42, 0.03), 0px 12px 16px -4px rgba(15, 23, 42, 0.08)',
				xl: '0px 8px 8px -4px rgba(15, 23, 42, 0.03), 0px 20px 24px -4px rgba(15, 23, 42, 0.08)',
				'2xl': '0px 24px 48px -12px rgba(15, 23, 42, 0.18)',
				'3xl': '0px 32px 64px -12px rgba(15, 23, 42, 0.14)',
			},
			spacing: {
				0.5: '2px',
				1: '4px',
				1.5: '6px',
				2: '8px',
				2.5: '10px',
				3: '12px',
				3.5: '14px',
				4: '16px',
				5: '20px',
				6: '24px',
				7: '28px',
				8: '32px',
				9: '36px',
				10: '40px',
				11: '44px',
				12: '48px',
				14: '56px',
				16: '64px',
				20: '80px',
				24: '96px',
				28: '112px',
				32: '128px',
				36: '144px',
				40: '160px',
				44: '176px',
				48: '192px',
				52: '208px',
				56: '224px',
				60: '240px',
				64: '256px',
				72: '288px',
				80: '320px',
				96: '384px',
			},
			fontSize: {
				xxs: '10px',
				xs: '12px',
				sm: '14px',
				base: '16px',
				lg: '18px',
				xl: '20px',
				'2xl': '24px',
				'3xl': '30px',
				'4xl': '36px',
				'5xl': '48px',
				'6xl': '60px',
				'7xl': '72px',
			},
			lineHeight: {
				3: '12px',
				4: '16px',
				5: '20px',
				6: '24px',
				7: '28px',
				8: '32px',
				9: '36px',
				10: '40px',
			},
			borderRadius: {
				none: '0',
				sm: '2px',
				DEFAULT: '4px',
				md: '8px',
				lg: '16px',
			},
			transitionProperty: {
				width: 'width',
			},
		},
	},
};
