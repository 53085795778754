import { FC } from 'react';

export const Info: FC = (props) => (
	<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM11 8C11 7.44772 11.4477 7 12 7H12.01C12.5623 7 13.01 7.44772 13.01 8C13.01 8.55228 12.5623 9 12.01 9H12C11.4477 9 11 8.55228 11 8ZM11.99 11C12.5423 11 12.99 11.4477 12.99 12V16C12.99 16.5523 12.5423 17 11.99 17C11.4377 17 10.99 16.5523 10.99 16V12C10.99 11.4477 11.4377 11 11.99 11Z"
			fill="currentColor"
		/>
	</svg>
);
