import tw, { css, styled } from 'twin.macro';

import { StyledTableCell, StyledTableHeaderCell } from '../Table/Table.styles';
import type { Props } from './Card';
import { StyledFooter } from './components/Footer/Footer.styles';
import { StyledHeader } from './components/Header/Header.styles';
import { StyledSection } from './components/Section/Section.styles';

type StyledProps = Pick<Props, 'spacing' | 'maxWidth' | 'divided'>;

export const StyledCard = styled.div<StyledProps>(({ spacing, maxWidth, divided }) => [
	tw`flex flex-col bg-white border border-gray-200 border-solid rounded-md`,

	maxWidth &&
		css`
			${tw`mx-auto`}
			max-width: ${maxWidth}px;
		`,

	css`
		& > ${StyledHeader}, ${StyledSection}, ${StyledFooter} {
			${spacing === 'small' && tw`px-4`}
			${spacing === 'medium' && tw`px-6`}
			${spacing === 'large' && tw`px-12`}
		}

		& > ${StyledHeader}, ${StyledFooter} {
			${(spacing === 'small' || spacing === 'medium') && tw`py-4`}
			${spacing === 'large' && tw`py-6`}
		}

		& > ${StyledSection} {
			${(spacing === 'small' || spacing === 'medium') && tw`py-4`}
			${spacing === 'large' && tw`py-12`}
			${divided && tw`border-b border-gray-200`}

			&:last-of-type {
				${divided && tw`border-b-0`}
			}
		}

		& > *:first-child {
			${tw`rounded-t-md`}
		}

		& > *:last-child {
			${tw`rounded-b-md`}
		}
	`,

	// If a table is embedded
	css`
		${StyledTableHeaderCell}:first-of-type, ${StyledTableCell}:first-of-type {
			${spacing === 'small' && tw`pl-4`}
			${spacing === 'medium' && tw`pl-6`}
			${spacing === 'large' && tw`pl-12`}
		}

		${StyledTableHeaderCell}:last-of-type, ${StyledTableCell}:last-of-type {
			${spacing === 'small' && tw`pr-4`}
			${spacing === 'medium' && tw`pr-6`}
			${spacing === 'large' && tw`pr-12`}
		}
	`,
]);
