import tw, { css, styled } from 'twin.macro';

import { StyledButton } from '../Button/Button.styles';

export interface StyledProps {
	rounded?: boolean;
	contentOnly?: boolean;
}
export const StyledIconButton = styled(StyledButton)(({ rounded, contentOnly }: StyledProps) => [
	rounded && tw`rounded-full`,
	contentOnly &&
		tw`
		text-gray-700 p-0
		bg-transparent hover:bg-transparent focus:bg-transparent active:bg-transparent
		border-none hover:border-none focus:border-none active:border-none
		focus:ring-0
	`,
	contentOnly &&
		css`
			height: -moz-available;
			height: -webkit-fill-available;
			height: fill-available;
		`,
	tw`p-1.5`,
]);
