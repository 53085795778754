import { Duration as LuxonDuration, DurationLikeObject, DurationObjectUnits } from 'luxon';

export class InvalidDurationException extends Error {}

export class Duration {
	private constructor(private duration: LuxonDuration) {
		if (!this.duration.isValid) {
			throw new InvalidDurationException('Not a valid duration');
		}
	}

	static fromISO(duration: string): Duration {
		return new Duration(LuxonDuration.fromISO(duration));
	}

	static fromObject(duration: {
		months?: number;
		weeks?: number;
		days?: number;
		hours?: number;
		minutes?: number;
		seconds?: number;
	}): Duration {
		return new Duration(LuxonDuration.fromObject(duration));
	}

	toISO(): string {
		const iso = this.duration.toISO();

		if (!iso) {
			throw new InvalidDurationException('Not a valid duration');
		}

		return iso;
	}

	toMillis(): number {
		return this.duration.toMillis();
	}

	toObject(): DurationObjectUnits {
		return this.duration.toObject();
	}

	toHumanSummary(): string {
		const minutes = this.duration.as('minutes');

		if (minutes < 0) {
			throw new Error('Duration cannot be negative.');
		}

		if (minutes < 1) {
			return 'a few seconds';
		} else if (minutes < 60) {
			return `${Math.ceil(minutes)} minute${Math.ceil(minutes) === 1 ? '' : 's'}`;
		} else if (minutes < 1440) {
			const hours = (minutes / 60) % 1 <= 0.5 ? Math.floor(minutes / 60) : Math.ceil(minutes / 60);
			return `${hours} hour${hours === 1 ? '' : 's'}`;
		} else if (minutes < 10080) {
			const days = (minutes / 1440) % 1 <= 0.5 ? Math.floor(minutes / 1440) : Math.ceil(minutes / 1440);
			return `${days} day${days === 1 ? '' : 's'}`;
		} else if (minutes < 43829) {
			const weeks = (minutes / 10080) % 1 <= 0.5 ? Math.floor(minutes / 10080) : Math.ceil(minutes / 10080);
			return `${weeks} week${weeks === 1 ? '' : 's'}`;
		} else if (minutes < 525600) {
			const months = (minutes / 43800) % 1 <= 0.5 ? Math.floor(minutes / 43800) : Math.ceil(minutes / 43800);
			return `${months} month${months === 1 ? '' : 's'}`;
		} else {
			const years = (minutes / 525600) % 1 <= 0.5 ? Math.floor(minutes / 525600) : Math.ceil(minutes / 525600);
			return `${years} year${years === 1 ? '' : 's'}`;
		}
	}

	as(unit: keyof DurationLikeObject): number {
		return this.duration.as(unit);
	}
}
