import tw, { styled } from 'twin.macro';

import { Align, Color, Overflow, Props, Size, Weight } from './Text';

const getColor = (color?: Color) => {
	switch (color) {
		case 'current':
			return tw`text-current`;
		default:
		case 'gray':
			return tw`text-gray-700`;
		case 'subdued':
			return tw`text-gray-500`;
		case 'white':
			return tw`text-white`;
		case 'red':
			return tw`text-red-700`;
	}
};

const getAlign = (align?: Align) => {
	switch (align) {
		case 'center':
			return tw`text-center`;
		case 'left':
			return tw`text-left`;
		case 'right':
			return tw`text-right`;
	}
};

const getSize = (size?: Size) => {
	switch (size) {
		case 'small':
			return tw`text-xs leading-[18px]`;
		case 'medium':
			return tw`text-sm leading-5`;
		case 'large':
			return tw`text-base leading-6`;
	}
};

const getWeight = (weight?: Weight) => {
	switch (weight) {
		case 'normal':
			return tw`font-normal`;
		case 'semibold':
			return tw`font-semibold`;
		case 'bold':
			return tw`font-bold`;
	}
};

const getOverflow = (overflow?: Overflow) => {
	switch (overflow) {
		case 'clip':
			return tw`text-clip`;
		case 'ellipsis':
			return tw`truncate`;
		case 'multiline':
			return tw`overflow-hidden text-wrap break-words`;
	}
};

export const StyledText = styled.p<Props>(({ align, color, size, weight, overflow }) => [
	tw`mb-0`,
	getAlign(align),
	getColor(color),
	getSize(size),
	getWeight(weight),
	getOverflow(overflow),
]);
