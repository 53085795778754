export class Clock {
	private static frozenTime: Date | null;

	static now(): Date {
		return this.frozenTime || new Date();
	}

	static freeze(): void {
		this.frozenTime = new Date();
	}

	static set(date: string): void {
		this.frozenTime = new Date(date);
	}

	static resume(): void {
		this.frozenTime = null;
	}
}
