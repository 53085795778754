import { Error as ErrorIcon } from '@cheqroom/icons';
import { CSSProperties, FC, ReactNode } from 'react';
import { theme } from 'twin.macro';

import type { Error } from '../../../@types/index.d';
import { Icon } from '../Icon/Icon';
import { Stack } from '../Stack/Stack';
import { Text } from '../Typography/Text/Text';
import { StyledLabel } from './Labelled.styles';

export interface Props {
	/** Error to display beneath the label */
	error?: Error;
	/** Additional text to aide in use */
	helpText?: ReactNode;
	/** A unique identifier for the label */
	htmlFor?: string;
	/** Text for the label */
	label?: ReactNode;
	/** Visually hide the label */
	labelHidden?: boolean;
	/** Toggle if the label indicates a required field */
	required?: boolean;
	children?: ReactNode;
	style?: CSSProperties;
}

export const Labelled: FC<Props> = ({
	children,
	error,
	helpText,
	htmlFor,
	label,
	labelHidden,
	required,
	style,
	...rest
}) => (
	<Stack vertical spacing="extraTight" style={style} {...rest}>
		{label && (
			<StyledLabel htmlFor={htmlFor} labelHidden={labelHidden} required={required}>
				{label}
			</StyledLabel>
		)}
		{children}

		{helpText && (
			<Text color="subdued" size="small">
				{helpText}
			</Text>
		)}
		{error && typeof error !== 'boolean' && (
			<Stack align="center" spacing="extraTight">
				<Icon source={ErrorIcon} color={theme`colors.red.700`} size="small" tw="flex-shrink-0" />
				<Text color="red" size="small">
					{error}
				</Text>
			</Stack>
		)}
	</Stack>
);
