import { FC, ReactNode } from 'react';

import { StyledSection } from './Section.styles';

export interface Props {
	subdued?: boolean;
	children?: ReactNode;
}

export const Section: FC<Props> = ({ subdued, children, ...other }) => (
	<StyledSection subdued={subdued} {...other}>
		{children}
	</StyledSection>
);
