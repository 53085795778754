import { createContext, FC, ReactNode, useContext } from 'react';

import Authentication, { AnonymousUser, User } from '../services/authentication';

type UserState = User | AnonymousUser;

export const UserStateContext = createContext<UserState | null>(null);

export const AuthenticationProvider: FC<{ children?: ReactNode; anonymous?: boolean }> = ({
	children,
	anonymous = false,
}) => <UserStateContext.Provider value={Authentication.getUser({ anonymous })}>{children}</UserStateContext.Provider>;

export const useAuth = (): UserState => {
	const context = useContext(UserStateContext);
	if (!context) {
		throw new Error('UserStateContext should be used within an AuthenticationProvider');
	}

	return context;
};
