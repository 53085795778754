import { Check, Error, Info, Warning } from '@cheqroom/icons';
import { FC } from 'react';

import { Icon } from '../../../Icon/Icon';
import { Stack } from '../../../Stack/Stack';
import { Text } from '../../../Typography/Text/Text';
import { getIconColor, StyledSubheader } from './Subheader.styles';

export type Variation = 'success' | 'warning' | 'error' | 'info' | 'default';

export interface Props {
	title: string;
	variation?: Variation;
}

const mapVariationToIcon = (variation: Variation) => {
	switch (variation) {
		case 'warning':
			return Warning;
		case 'error':
			return Error;
		case 'success':
			return Check;
		default:
			return Info;
	}
};

export const Subheader: FC<Props> = ({ title, variation = 'default' }) => (
	<StyledSubheader justify="center" align="center" spacing="tight" variation={variation}>
		<Icon source={mapVariationToIcon(variation)} color={getIconColor(variation)} size="small" />
		<Stack tw="w-full" vertical spacing="extraTight">
			{title && <Text color="current">{title}</Text>}
		</Stack>
	</StyledSubheader>
);
