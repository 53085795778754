import { FC } from 'react';

export const Warning: FC = (props) => (
	<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12.8638 4.46537C12.4779 3.80386 11.5221 3.80386 11.1362 4.46537L4.11828 16.4961C3.7294 17.1628 4.21027 18 4.98206 18H19.0179C19.7897 18 20.2706 17.1628 19.8817 16.4961L12.8638 4.46537ZM9.40865 3.45763C10.5663 1.47311 13.4337 1.47311 14.5913 3.45762L21.6092 15.4884C22.7759 17.4883 21.3333 20 19.0179 20H4.98206C2.66669 20 1.22407 17.4883 2.39072 15.4884L9.40865 3.45763ZM12.0133 7.99998C12.5656 8.00243 13.0113 8.45213 13.0089 9.00441L13 11.0044C12.9975 11.5567 12.5478 12.0024 11.9955 12C11.4433 11.9975 10.9975 11.5478 11 10.9955L11.0089 8.99552C11.0113 8.44325 11.461 7.99752 12.0133 7.99998ZM11 15C11 14.4477 11.4477 14 12 14H12.0089C12.5612 14 13.0089 14.4477 13.0089 15C13.0089 15.5523 12.5612 16 12.0089 16H12C11.4477 16 11 15.5523 11 15Z"
			fill="currentColor"
		/>
	</svg>
);
