import 'twin.macro';

import { ComponentPropsWithoutRef, forwardRef } from 'react';

import { Icon, IconSource } from '../Icon/Icon';
import { Props as LinkProps } from '../Link/Link';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import { StyledButton, StyledButtonContent } from './Button.styles';

export type Size = 'small' | 'medium' | 'large';

export type Variation = 'primary' | 'secondary' | 'destructive';

export interface Props extends ComponentPropsWithoutRef<'button'> {
	loading?: boolean;
	inverted?: boolean;
	size?: Size;
	variation?: Variation;
	leftIcon?: IconSource;
	rightIcon?: IconSource;
	pressed?: boolean;
}

const getIconSize = (size?: Size): Size => {
	switch (size) {
		default:
		case 'small':
		case 'medium':
			return 'small';
		case 'large':
			return 'medium';
	}
};

export const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, Props & LinkProps>(
	(
		{
			children,
			disabled,
			loading = false,
			inverted = false,
			onClick,
			size = 'medium',
			type = 'button',
			variation = 'primary',
			leftIcon,
			rightIcon,
			url,
			external,
			pressed,
			...rest
		},
		ref
	) => {
		const externalProps = external ? { target: '_blank', rel: 'noopener noreferrer' } : null;

		return (
			<StyledButton
				disabled={disabled}
				isLoading={loading}
				onClick={onClick}
				size={size}
				type={url ? undefined : type}
				variation={variation}
				inverted={inverted}
				pressed={pressed}
				// TabIndex should be explicitly set for Safari, so we override the default setting that disables keyboard navigation with Tab.
				tabIndex={disabled ? -1 : 0}
				{...rest}
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				//@ts-ignore
				ref={ref}
				as={url ? 'a' : 'button'}
				href={url}
				{...externalProps}
			>
				{(children || leftIcon || rightIcon) && (
					<StyledButtonContent size={size} isLoading={loading}>
						{leftIcon && <Icon color="currentColor" size={getIconSize(size)} source={leftIcon} />}
						{children}
						{rightIcon && <Icon color="currentColor" size={getIconSize(size)} source={rightIcon} />}
					</StyledButtonContent>
				)}

				{loading && <LoadingSpinner tw="absolute text-current" size={size} />}
			</StyledButton>
		);
	}
);

Button.displayName = 'Button';
