import tw, { css, styled, theme } from 'twin.macro';

import { Stack } from '../../../Stack/Stack';
import { Props, Variation } from './Subheader';

type StyledSubheaderProps = Pick<Props, 'variation'>;

export const getVariation = (variation?: Variation) => {
	switch (variation) {
		case 'error':
			return tw`bg-red-50 border-y border-red-300 text-red-900`;
		case 'warning':
			return tw`bg-yellow-50 border-y border-yellow-300 text-yellow-900`;
		case 'success':
			return tw`bg-green-50 border-y border-green-300 text-green-900`;
		case 'info':
			return tw`bg-blue-50 border-y border-blue-300 text-blue-900`;
		default:
			return tw`bg-white border-y border-gray-200 text-gray-700`;
	}
};

export const getIconColor = (variation?: Variation): string => {
	switch (variation) {
		case 'error':
			return theme`colors.red.500`;
		case 'warning':
			return theme`colors.yellow.500`;
		case 'success':
			return theme`colors.green.500`;
		case 'info':
			return theme`colors.blue.500`;
		default:
			return theme`colors.indigo.500`;
	}
};

export const StyledSubheader = styled(Stack)<StyledSubheaderProps>(({ variation }) => [
	tw`-mb-4 -mx-6 mt-4 px-4 py-2`,
	css`
		margin-bottom: calc(-1rem - 1px);
	`,
	getVariation(variation),
]);
