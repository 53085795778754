import { createContext, useContext } from 'react';

import { noop } from '../../util/noop';

type PopoverDispatch = {
	onClose: () => void;
	onToggle: () => void;
};

export const PopoverStateContext = createContext<boolean | undefined>(false);
export const PopoverDispatchContext = createContext<PopoverDispatch>({
	onToggle: noop,
	onClose: noop,
});

export const usePopoverStateContext = (): boolean => {
	const context = useContext(PopoverStateContext);
	if (context === undefined) {
		throw new Error('usePopoverStateContext must be used within a PopoverStateContext Provider');
	}
	return context;
};

export const usePopoverDispatchContext = (): PopoverDispatch => {
	const context = useContext(PopoverDispatchContext);
	if (context === undefined) {
		throw new Error('usePopoverDispatchContext must be used within a PopoverDispatchContext Provider');
	}
	return context;
};
