import { FC, SVGProps } from 'react';

export type IllustrationSource = FC<SVGProps<SVGSVGElement>>;

export interface Props {
	source: IllustrationSource;
}

export const Illustration: FC<Props> = ({ source: SourceComponent, ...rest }) => (
	<SourceComponent style={{ flexShrink: 0 }} {...rest} />
);
