import { ComponentPropsWithoutRef, forwardRef } from 'react';

import { Size, Variation } from '../Button/Button';
import { Icon, IconSource } from '../Icon/Icon';
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner';
import { StyledIconButton } from './IconButton.styles';

export interface Props extends ComponentPropsWithoutRef<'button'> {
	inverted?: boolean;
	rounded?: boolean;
	size?: Size;
	source: IconSource;
	variation?: Variation | 'content-only';
	isLoading?: boolean;
}

const IconButton = forwardRef<HTMLButtonElement, Props>(
	({ size = 'medium', source, type = 'button', variation = 'primary', isLoading = false, ...rest }, ref) => (
		<StyledIconButton
			isLoading={isLoading}
			size={size}
			type={type}
			/* We add a custom variant on the IconButton since it doesn't relate to the actual button styling */
			variation={variation === 'content-only' ? 'secondary' : variation}
			contentOnly={variation === 'content-only'}
			ref={ref}
			{...rest}
		>
			{isLoading ? (
				<LoadingSpinner tw="absolute text-current" size={size} />
			) : (
				<Icon color="currentColor" source={source} size={size} />
			)}
		</StyledIconButton>
	)
);

IconButton.displayName = 'IconButton';

export { IconButton };
