import tw, { styled } from 'twin.macro';

import type { Align, Level } from './Heading';

interface StyledProps {
	align: Align;
	level: Level;
	truncate?: boolean;
}

const getLevel = (level: Level) => {
	switch (level) {
		default:
		case 'h1':
			return tw`text-gray-900 font-bold text-2xl leading-8`;
		case 'h2':
			return tw`text-gray-900 font-semibold text-xl leading-6`;
		case 'h3':
			return tw`text-gray-900 font-semibold text-lg leading-6`;
		case 'h4':
			return tw`text-gray-900 font-semibold text-base leading-6`;
		case 'h5':
			return tw`text-gray-900 font-semibold text-sm leading-5`;
	}
};

const getAlign = (align: Align) => {
	switch (align) {
		case 'center':
			return tw`text-center`;
		case 'left':
			return tw`text-left`;
		case 'right':
			return tw`text-right`;
	}
};

const getTruncate = (truncate = false) => {
	return truncate && tw`truncate`;
};

export const StyledHeading = styled.h2<StyledProps>(({ align, level, truncate }) => [
	tw`mb-0`,
	getAlign(align),
	getLevel(level),
	getTruncate(truncate),
]);
