import { useToggle } from '@cheqroom/hooks';
import {
	arrow,
	autoUpdate,
	FloatingArrow,
	Middleware,
	offset,
	Placement,
	size,
	Strategy,
	useDismiss,
	useFloating,
	useInteractions,
} from '@floating-ui/react';
import { Children, cloneElement, FC, isValidElement, ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import { flushSync } from 'react-dom';

import { theme } from '../../../tailwind.config';
import useBodyClass from '../../hooks/useBodyClass';
import { Overlay } from '../Overlay/Overlay';
import { Content, Props as ContentProps } from './components/Content/Content';
import { Props as TriggerProps, Trigger } from './components/Trigger/Trigger';
import { PopoverDispatchContext, PopoverStateContext } from './Popover.context';
import { StyledPopover } from './Popover.styles';

export interface PopoverComposition {
	Content: typeof Content;
	Trigger: typeof Trigger;
}

export interface Props {
	/** Show backdrop */
	backdrop?: boolean;
	/** Callback on closed */
	onClose?: () => void;
	/** Callback on toggled */
	onToggle?: () => void;
	onToggleChange?: (toggled: boolean) => void;
	/** Placement */
	placement?: Placement;
	/** Popover is toggled */
	toggled?: boolean;
	/** Strategy */
	strategy?: Strategy;
	/** Modifiers */
	middleware?: Middleware[];
	/** Custom popover wrapper */
	renderWrapper?: () => typeof StyledPopover;
	children?: ReactNode;
	/** Show arrow */
	showArrow?: boolean;
}

const Popover: FC<Props> & PopoverComposition = ({
	backdrop = false,
	children,
	onClose: controlledOnClose,
	onToggle: controlledOnToggle,
	onToggleChange,
	placement,
	toggled: controlledToggled,
	showArrow = false,
	middleware = [offset({ mainAxis: 5, crossAxis: 0 })],
	strategy = 'absolute',
	renderWrapper = () => StyledPopover,
}) => {
	const popoverRef = useRef<HTMLDivElement>(null);
	const arrowRef = useRef(null);

	const isControlled = !!controlledOnClose && !!controlledOnToggle && controlledToggled !== undefined;

	const { onClose: unControlledOnClose, onToggle: unControlledOnToggle, toggled: unControlledToggled } = useToggle();

	const handleOnClose = useCallback(() => {
		if (isControlled) {
			controlledOnClose?.();
		} else {
			unControlledOnClose();
		}
	}, [controlledOnClose, isControlled, unControlledOnClose]);

	const handleOnToggle = useCallback(() => {
		if (isControlled) {
			controlledOnToggle?.();
		} else {
			unControlledOnToggle();
		}
	}, [controlledOnToggle, isControlled, unControlledOnToggle]);

	const toggled = useMemo(
		() => (isControlled ? controlledToggled : unControlledToggled),
		[controlledToggled, isControlled, unControlledToggled]
	);

	onToggleChange?.(toggled);

	// Add helper class to body if popover with backdrop is used
	useBodyClass('popover-open', backdrop && toggled);

	const [maxHeight, setMaxHeight] = useState<number | undefined>();

	const { floatingStyles, refs, context } = useFloating({
		placement,
		strategy,
		middleware: [
			...middleware,
			arrow({
				element: arrowRef,
			}),
			size({
				apply({ availableHeight }) {
					flushSync(() => setMaxHeight(availableHeight));
				},
			}),
		],
		whileElementsMounted: autoUpdate,
		open: toggled,
		onOpenChange: handleOnToggle,
	});

	const dismiss = useDismiss(context, {
		referencePress: true,
	});

	const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

	const childrenWithProps = Children.map(children, (child) => {
		if (isValidElement(child)) {
			if (child.type === Trigger) {
				return cloneElement(child, {
					ref: refs.setReference,
					...getReferenceProps(),
					...child.props,
				} as TriggerProps);
			}

			if (child.type === Content) {
				return cloneElement(child, {
					arrow: showArrow && (
						<FloatingArrow
							ref={arrowRef}
							context={context}
							strokeWidth={1}
							stroke={theme.colors.gray[200]}
							fill={theme.colors.white}
						/>
					),
					ref: refs.setFloating,
					maxHeight,
					style: floatingStyles,
					context,
					...getFloatingProps(),
					...child.props,
				} as ContentProps);
			}
		}
		return child;
	});

	const PopoverWrapper = renderWrapper();

	return (
		<PopoverStateContext.Provider value={toggled}>
			<PopoverDispatchContext.Provider value={{ onClose: handleOnClose, onToggle: handleOnToggle }}>
				<PopoverWrapper ref={popoverRef} toggled={toggled}>
					{childrenWithProps}
				</PopoverWrapper>

				{backdrop && toggled && <Overlay />}
			</PopoverDispatchContext.Provider>
		</PopoverStateContext.Provider>
	);
};

Popover.Content = Content;
Popover.Trigger = Trigger;

export { Popover };
