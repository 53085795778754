import { FloatingOverlay } from '@floating-ui/react';
import tw, { styled } from 'twin.macro';

export const StyledOverlay = styled(FloatingOverlay)`
	${tw`fixed inset-0 pointer-events-none`}
	z-index: 555555; //higher than any element in the web app

	// Increase specificity to force override margin by space-y class
	&&&& {
		${tw`my-0`}
	}

	div {
		${tw`absolute inset-0`}

		// Jessie background color
		background: rgba(75, 87, 108, 0.1);
	}
`;
