import { FC } from 'react';

export const Error: FC = (props) => (
	<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM12 7C12.5523 7.00001 13 7.44773 13 8.00002L12.9999 12C12.9999 12.5523 12.5522 13 11.9999 13C11.4476 13 10.9999 12.5523 10.9999 12L11 7.99998C11 7.4477 11.4477 6.99999 12 7ZM10.9999 16C10.9999 15.4477 11.4477 15 11.9999 15H12.0099C12.5622 15 13.0099 15.4477 13.0099 16C13.0099 16.5523 12.5622 17 12.0099 17H11.9999C11.4477 17 10.9999 16.5523 10.9999 16Z"
			fill="currentColor"
		/>
	</svg>
);
