import 'twin.macro';

import { Loading } from '@cheqroom/icons';
import { Icon } from '@cheqroom/ui';
import type { Size } from '@cheqroom/ui/src/components/Icon/Icon';
import { FC } from 'react';

type Props = {
	size?: Size;
};

export const LoadingSpinner: FC<Props> = ({ size = 'medium', ...rest }) => (
	<Icon source={Loading} size={size} tw="animate-spin text-indigo-500" {...rest} />
);
