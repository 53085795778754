import { useMediaQuery } from '@cheqroom/hooks';
import { FC, HTMLAttributes, ReactNode } from 'react';

import { StyledCard } from './Card.styles';
import { Footer } from './components/Footer/Footer';
import { Header } from './components/Header/Header';
import { Section } from './components/Section/Section';

interface ComponentComposition {
	Header: typeof Header;
	Footer: typeof Footer;
	Section: typeof Section;
}

type Padding = 'small' | 'medium' | 'large' | 'auto';

export interface Props extends HTMLAttributes<HTMLDivElement> {
	children?: ReactNode;
	sectioned?: boolean;
	spacing?: Padding;
	maxWidth?: number;
	divided?: boolean;
}

const Card: FC<Props> & ComponentComposition = ({
	children,
	sectioned = false,
	spacing = 'medium',
	divided = false,
	...other
}) => {
	const mediaQuery = useMediaQuery('(max-width: 640px)');
	const autoSpacing = mediaQuery ? 'medium' : 'large';

	return (
		<StyledCard spacing={spacing === 'auto' ? autoSpacing : spacing} divided={divided} {...other}>
			{sectioned ? <Card.Section>{children}</Card.Section> : children}
		</StyledCard>
	);
};
Card.Header = Header;
Card.Footer = Footer;
Card.Section = Section;

export { Card };
