import { AnalyticsBrowser } from '@segment/analytics-next';

import { AnalyticsPlugin, AnalyticsProperties, Properties } from '../analytics';

interface SegmentContext {
	platform: string;
}

interface SegmentConfig {
	apiKey: string;
	apiHost: string;
}

export class Segment implements AnalyticsPlugin {
	private client: AnalyticsBrowser;
	private traits = {};
	private customIntegrations = {};

	private context: SegmentContext = {
		platform: 'Web',
	};

	constructor(private readonly config: SegmentConfig) {
		this.client = AnalyticsBrowser.load(
			{
				writeKey: config.apiKey,
			},
			{
				integrations: {
					'Segment.io': { apiHost: config.apiHost || 'api.segment.io/v1' },
				},
				retryQueue: true,
			}
		);
	}

	get name(): string {
		return 'segment';
	}

	identify({ userId, ...traits }: AnalyticsProperties): void {
		this.traits = traits;
		void this.client?.identify(userId, traits, {
			context: this.context,
		});

		void this.client?.group(traits.workspaceId, {
			name: traits.workspaceName,
			workspaceName: traits.workspaceName,
			industry: traits.industry,
			renewalDate: traits.renewalDate,
			creationDate: traits.creationDate,
			conversionDate: traits.conversionDate,
			recurlyAccountCode: traits.recurlyAccountCode,
			ownerId: userId,
			enabledFeatures: traits.enabledFeatures,
			availableFeatures: traits.availableFeatures,
			featureFlags: traits.featureFlags,
			itemQuota: traits.itemQuota,
			itemQuotaPercentage: traits.itemQuotaPercentage,
		});
	}

	initialize(): void {
		// init was done in constructor
	}

	page(): void {
		// Get the route name from the hash without the id.
		const route = window.location.pathname.replace(/\/\w{22}($|\/)/, '/:id$1');
		const name = route ? `${route}` : '/';

		void this.client?.page(undefined, name, {
			path: window.location.pathname + window.location.hash,
			url: window.location.toString(),
		});
	}

	track(event: string, source: string, props?: Properties): void {
		void this.client?.track(
			event,
			{
				...props,
				source,
			},
			{
				...this.context,
				traits: this.traits,
			}
		);
	}
}
