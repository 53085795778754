import { OptimizelyProvider } from '@optimizely/react-sdk';
import { FC, ReactNode } from 'react';

import Client from '../services/feature-flags';
import { useAuth } from './Authentication.context';

export const FeatureFlagsProvider: FC<{ children?: ReactNode }> = ({ children }) => {
	const user = useAuth();

	return (
		<OptimizelyProvider
			optimizely={Client}
			user={{
				id: user?.id || 'test',
				attributes: {
					...(user?.workspace.isInBetaProgram !== undefined
						? { isInBetaProgram: user?.workspace.isInBetaProgram }
						: {}),
					...(user?.organisationId !== undefined ? { organisationId: user?.organisationId } : {}),
					workspaceId: user?.workspace.id,
					...(user?.subscription?.kind !== undefined ? { subscriptionKind: user?.subscription?.kind } : {}),
					...(user?.subscription?.plan?.family !== undefined
						? { subscriptionPlanFamily: user?.subscription?.plan?.family }
						: {}),
					...(user?.subscription?.plan?.groupKey !== undefined
						? { subscriptionPlanGroup: user?.subscription?.plan?.groupKey }
						: {}),
					creationDateInMillis: user?.workspace.signedUpAt ? user.workspace.signedUpAt.toMillis() : null,
				},
			}}
		>
			{children}
		</OptimizelyProvider>
	);
};
